import React, { Component } from "react"
import ThemeContext from "../context/theme-context"

class ThemeProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dark: false,
    }
  }

  componentDidMount() {
    const isDark = JSON.parse(localStorage.getItem("dark") || false)

    if (isDark) {
      this.setState({ dark: isDark })
    }
  }

  componentDidUpdate(prevState) {
    const { dark } = this.state

    if (prevState.dark !== dark) {
      localStorage.setItem("dark", JSON.stringify(dark))
    }
  }

  toggleDark = () => {
    this.setState(prevState => ({ dark: !prevState.dark }))
  }

  render() {
    const { children } = this.props
    const { dark } = this.state

    return (
      <ThemeContext.Provider
        value={{
          dark,
          toggleDark: this.toggleDark,
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeProvider
